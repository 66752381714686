import React, { useEffect, useState } from 'react';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@material-ui/core';
import { BarChartRounded, PieChartRounded, Print, ShowChart } from '@material-ui/icons';
import { Area, AreaChart, Bar, BarChart, CartesianGrid, Cell, Legend, Pie, PieChart, ResponsiveContainer, Sector, Tooltip, XAxis, YAxis } from 'recharts';
import sweetAlert from '../../../f5/utils/sweetAlert';
import axios_instance from '../../../services/auth/jwt/config';
import moment from 'moment';

interface DashboardOrderProps {

}

const COLORS = [
    "#FF6384", // Red
    "#36A2EB", // Blue
    "#FFCE56", // Yellow
    "#4BC0C0", // Teal
    "#9966FF", // Purple
    "#FF9F40", // Orange
    "#C9CBCF", // Gray
    "#60D394", // Green
    "#F984E5", // Pink
    "#F4A261", // Light Orange
    "#2A9D8F", // Dark Teal
    "#264653"  // Dark Blue
];
// @ts-ignore
const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                {payload.name}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${payload.nome}`}</text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                {`${value} (${(percent * 100).toFixed(2)}%)`}
            </text>
        </g>
    );
};

export default function DashboardOrder({ ...props }: DashboardOrderProps) {
    const [loading, setLoading] = useState({ is: true, message: 'Carregando' })
    const [mes, setMes] = useState(moment().month())
    const [year, setYear] = useState(moment().year())
    const [activeIndex, setActiveIndex] = useState(0);
    const [dashboard, setDashboard] = useState({
        meses: [],
        tipos: [],
        departamentos: []
    })

    useEffect(() => {
        setLoading({ is: true, message: 'Carregando' })
        let start = moment().set('month', mes).set('year', year).subtract(1, 'month').startOf('month').format('YYYY-MM-DD')
        let end = moment().set('month', mes).set('year', year).endOf('month').format('YYYY-MM-DD')
        axios_instance.get<{ success: boolean, graphs: any }>(`ordem_servico/dashboard?start=${start}&end=${end}`).then((response) => {
            let r = response.data
            if (r.success) {
                setDashboard(r.graphs)
            }
        }).catch(() => {
            sweetAlert('error', 'Erro ao carregar inscrições')
        }).finally(() => {
            setLoading({ is: false, message: '' })
        })
    }, [year, mes])

    const onPrint = () => {
        window.print()
    }

    // @ts-ignore
    const onPieEnter = (_, index) => {
        setActiveIndex(index);
    };

    // @ts-ignore
    return <PageContainer>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h4" component="h2">
                    Dashboard
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth variant='outlined'>
                    <InputLabel>Mês de Comparação</InputLabel>
                    <Select
                        label="Mês de Comparação"
                        value={mes}
                        fullWidth
                        // @ts-ignore
                        onChange={(e) => setMes(e.target.value)}
                    >
                        <MenuItem value={1}>Janeiro</MenuItem>
                        <MenuItem value={2}>Fevereiro</MenuItem>
                        <MenuItem value={3}>Março</MenuItem>
                        <MenuItem value={4}>Abril</MenuItem>
                        <MenuItem value={5}>Maio</MenuItem>
                        <MenuItem value={6}>Junho</MenuItem>
                        <MenuItem value={7}>Julho</MenuItem>
                        <MenuItem value={8}>Agosto</MenuItem>
                        <MenuItem value={9}>Setembro</MenuItem>
                        <MenuItem value={10}>Outubro</MenuItem>
                        <MenuItem value={11}>Novembro</MenuItem>
                        <MenuItem value={12}>Dezembro</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <TextField
                    label="Ano"
                    type="number"
                    variant="outlined"
                    value={year}
                    fullWidth
                    // @ts-ignore
                    onChange={(e) => setYear(e.target.value ? parseInt(e.target.value) : 0)}
                />
            </Grid>
            <Grid item xs={5}></Grid>
            <Grid item xs={1} alignItems='flex-end' justifyContent='flex-end'>
                <Button variant="contained" color="primary" onClick={onPrint}>
                    <Print />
                </Button>
            </Grid>
            <Grid item xs={12}>
                <Paper>
                    <Box p={2}>
                        <Box mb={3} display="flex" alignItems="center">
                            <ShowChart />
                            <Box ml={1} fontWeight={700}>
                                Fluxo De Ordens de Serviço
                            </Box>
                        </Box>
                        <Box>
                            <ResponsiveContainer width="100%" height={300}>
                                <AreaChart data={dashboard?.meses} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                                    <XAxis dataKey="dia" />
                                    <YAxis />
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <Tooltip labelStyle={{ color: 'black' }} itemStyle={{ color: 'black' }} cursor={false} />
                                    <Legend />
                                    <defs>
                                        <linearGradient id="colorCurrent" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#f7aa1b" stopOpacity={0.8} />
                                            <stop offset="95%" stopColor="#f7aa1b" stopOpacity={0} />
                                        </linearGradient>
                                        <linearGradient id="colorLast" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#a19b91" stopOpacity={0.8} />
                                            <stop offset="95%" stopColor="#a19b91" stopOpacity={0} />
                                        </linearGradient>
                                    </defs>
                                    <Area
                                        type="monotone"
                                        dataKey="anterior"
                                        name="Mês Anterior"
                                        stroke="#a19b91"
                                        fill="url(#colorLast)"
                                        fillOpacity={1}
                                    />
                                    <Area
                                        type="monotone"
                                        dataKey="atual"
                                        name="Mês Atual"
                                        stroke="#f7aa1b"
                                        fill="url(#colorCurrent)"
                                        fillOpacity={1}
                                    />
                                </AreaChart>
                            </ResponsiveContainer>
                        </Box>
                    </Box>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper>
                    <Box p={2}>
                        <Box mb={3} display="flex" alignItems="center">
                            <PieChartRounded />
                            <Box ml={1} fontWeight={700}>
                                Ordens de Serviço por Tipo
                            </Box>
                        </Box>
                        <Box>
                            <ResponsiveContainer width="100%" height={400}>
                                <PieChart width={400} height={400}>
                                    <Legend verticalAlign="middle" align="right" layout="vertical" />
                                    <Pie
                                        activeIndex={activeIndex}
                                        onMouseEnter={onPieEnter}
                                        activeShape={renderActiveShape}
                                        data={dashboard?.tipos}
                                        cx="50%"
                                        cy="50%"
                                        outerRadius={150}
                                        fill="#8884d8"
                                        dataKey="presente"
                                        nameKey="nome"
                                    >
                                        {dashboard?.tipos.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                    </Pie>
                                </PieChart>
                                {/* <BarChart data={dashboard?.tipos} layout='vertical'>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis type="number" />
                                    <YAxis dataKey="nome" type="category" width={200} />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="passado" label={{ position: 'right' }} name="Mês Anterior" fill="#a19b91" />
                                    <Bar dataKey="presente" label={{ position: 'right' }} name="Mês Atual" fill="#f7aa1b" />
                                </BarChart> */}
                            </ResponsiveContainer>
                        </Box>
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    </PageContainer>
}