import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import React from 'react';
import { Ordem } from './ObraOrderIndex';
import { Document, Image, Page, PDFViewer, StyleSheet, Text, View } from '@react-pdf/renderer';
import { Close } from '@material-ui/icons';
import moment from 'moment';

interface DialogPrintOrdemProps {
    open: boolean;
    onClose: () => void;
    ordem: Ordem | null;
}

export default function DialogPrintOrdem({ open, onClose, ordem, ...props }: DialogPrintOrdemProps) {
    if (!ordem) return null;
    return <Dialog open={open} onClose={onClose} fullScreen>
        <DialogTitle>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h6">Ordem de serviço</Typography>
                <IconButton onClick={onClose}>
                    <Close />
                </IconButton>
            </Box>
        </DialogTitle>
        <DialogContent>
            <PDFViewer width="100%" height="100%">
                {/** @ts-ignore */}
                <Document>
                    {/** @ts-ignore */}
                    <Page size="A4" style={{ padding: 5 }}>
                        <View fixed style={{ marginBottom: 10 }}>
                            <Image source={require('../../../asset/logo.png')} style={styles.logo} />
                            <Text
                                style={{
                                    textAlign: 'center',
                                    fontSize: 18,
                                    marginTop: 10,
                                    marginBottom: 5,
                                }}>
                                Protocolo: {ordem.protocolo}
                            </Text>
                        </View>
                        <View style={styles.container} wrap={false}>
                            <Text style={[styles.title, { marginTop: 10 }]}>Informações</Text>
                            <View>
                                <View style={styles.row}>
                                    <Text style={[styles.normal, { fontSize: 12 }]}>
                                        <Text style={{ color: 'grey' }}>Solicitante:</Text>
                                        {ordem?.solicitante}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={[styles.normal, { fontSize: 12 }]}>
                                        <Text style={{ color: 'grey' }}>Local:</Text>
                                        {ordem.local}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={[styles.normal, { fontSize: 12 }]}>
                                        <Text style={{ color: 'grey' }}>Endereço:</Text>
                                        {ordem.endereco}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={[styles.normal, { fontSize: 12 }]}>
                                        <Text style={{ color: 'grey' }}>Status:</Text>
                                        {ordem?.status?.nome}</Text>
                                </View>
                                <View>
                                    <Text style={[styles.normal, { fontSize: 12 }]}>
                                        <Text style={{ color: 'grey' }}>Aberta em:</Text>
                                        {` ${moment(ordem.created_at).format("DD/MM/YYYY [às] HH:mm")}`}
                                    </Text>
                                </View>
                            </View>
                        </View>
                        <View style={styles.container} wrap={false}>
                            <View>
                                <Text style={styles.title}>Solicitação</Text>
                            </View>
                            <View>
                                <Text style={[styles.normal, { fontSize: 12, color: 'grey' }]}>
                                    Tipo: <Text style={{ color: 'black' }}>{ordem?.tipo?.nome ?? 'Sem tipo'}</Text>
                                </Text>
                            </View>
                            <View>
                                <Text style={[styles.normal, { fontSize: 12 }]}>
                                    <Text style={{ color: 'grey' }}>Descrição: {`\n`}</Text>
                                    {ordem.descricao}
                                </Text>
                            </View>
                        </View>
                        <View style={styles.container} wrap={false}>
                            <View>
                                <View>
                                    <Text style={styles.title}>Técnicos</Text>
                                </View>
                            </View>
                            <View>
                                <View>
                                    <Text style={[styles.normal, { fontSize: 12 }]}>
                                        <Text style={{ color: 'grey' }}>Responsável:</Text>
                                        {ordem?.atendentes?.filter(atendente => atendente.pivot.principal)?.map(atendente => atendente.nome).join(', ') || 'Sem responsável'}
                                    </Text>
                                </View>
                                <View>
                                    <Text style={[styles.normal, { fontSize: 12 }]}>
                                        <Text style={{ color: 'grey' }}>Auxiliares:</Text>
                                        {ordem?.atendentes?.filter(atendente => !atendente.pivot.principal)?.map(atendente => atendente.nome).join(', ') || 'Sem auxiliares'}
                                    </Text>
                                </View>
                            </View>
                        </View>
                        <View style={styles.container} wrap={false}>
                            <View>
                                <View>
                                    <Text style={styles.title}>Soluções</Text>
                                </View>
                            </View>
                            <View>
                                <View>
                                    <Text style={[styles.normal, { fontSize: 12 }]}>
                                        <Text style={{ color: 'grey' }}>Intervenção: {`\n`}</Text>
                                        {ordem?.intervencao}
                                    </Text>
                                </View>
                                <View>
                                    <Text style={[styles.normal, { fontSize: 12 }]}>
                                        <Text style={{ color: 'grey' }}>Materiais e Equipamentos: {`\n`}</Text>
                                        {ordem?.materiais_equipamentos}
                                    </Text>
                                </View>
                                <View>
                                    <Text style={[styles.normal, { fontSize: 12 }]}>
                                        <Text style={{ color: 'grey' }}>Observações: {`\n`}</Text>
                                        {ordem?.observacao}
                                    </Text>
                                </View>
                            </View>
                        </View>
                    </Page>
                </Document>
            </PDFViewer>
        </DialogContent>
    </Dialog>
}

const styles = StyleSheet.create({
    container: {
        padding: 5,
        marginBottom: 10,
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    title: {
        fontSize: 16,
        marginBottom: 5,
    },
    normal: {
        borderStyle: 'solid',
        borderColor: '#aaa',
        borderWidth: '1px',
        borderRadius: 5,
        paddingLeft: 5,
        paddingTop: 5,
        width: 570,
        marginTop: 2,
    },
    subtitle: {
        fontSize: 12,
        fontWeight: 'bold',
    },
    datetime: {
        fontSize: 12,
        color: 'grey'
    },
    logo: {
        width: 90,
        height: 90,
        paddingTop: 20,
        paddingBottom: 20,
        position: 'absolute',
        textAlign: 'center',
        marginLeft: 30,
        objectFit: 'contain',
    },
});