import { Box, Dialog, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import React from 'react';
import FormFile from './FormFile';

interface ViewInscricaoProps {
    steps: Array<any>,
    data: any,
    user: any,
    open: boolean,
    onClose: () => void,
    id: string,
}

export default function ViewInscricao({ open, onClose, steps, data, user, id, ...props }: ViewInscricaoProps) {
    return <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
        <DialogTitle>
            Informações da Inscrição
        </DialogTitle>
        <DialogContent>
            <h2>Informações do Usuário</h2>
            <Box mt={2}>
                <strong>Nome</strong>: {user?.nome ?? 'Não informado'}
            </Box>
            {steps.map((step, index) =>
                <Box key={index} mt={2}>
                    <h3>{step.title}</h3>
                    {step.type === 'file' ? <Grid container spacing={2}>
                        {step.fields.map((field: any, index: number) => {
                            return <Grid item key={index} >
                                <strong>{field.label}</strong>
                                <br />
                                <FormFile
                                    id={id}
                                    type={field.name}
                                    name={field.label}
                                />
                            </Grid>
                        })}
                    </Grid> : <Box>{step.fields.map((field: any, index: number) => {
                        let value = data[field.name]
                        switch (field.type) {
                            case 'choice':
                                value = value ? 'Sim' : 'Não';
                                break;
                            case 'accept':
                                return null;
                            case 'file':
                                return <Box key={index} >
                                    <strong>{field.label}</strong>
                                    <FormFile
                                        id={id}
                                        type={field.name}
                                        name={field.label}
                                    />
                                </Box>
                                break;
                            default:
                        }
                        return <div key={index}>
                            <strong>{field.label}</strong>: {value}
                        </div>
                    })}
                    </Box>}
                </Box>
            )}
        </DialogContent>
    </Dialog>
}