import MaterialTable from '@material-table/core';
import React, { useEffect, useState } from 'react';
import { tableIcons, tableLocalization } from '../../../@jumbo/constants/TableConstants';
import { useHistory, useParams } from 'react-router';
import { Visibility } from '@material-ui/icons';
import axios_instance from '../../../services/auth/jwt/config';

interface FormListProps {

}

export default function FormList({ ...props }: FormListProps) {
    const [loading, setLoading] = useState({ is: true, message: 'Carregando' })
    const [forms, setForms] = useState<any[]>([])
    const { slug } = useParams<{ slug: string }>()
    const history = useHistory()

    useEffect(() => {
        axios_instance.get<{ success: boolean, data: any[] }>(`form/index/${slug}`).then((response) => {
            let r = response.data
            if (r.success) {
                setForms(r.data)
            }
        }).catch(() => {

        }).finally(() => {
            setLoading({ is: false, message: '' })
        })
    }, [])

    return <div className="app-wrapper">
        <MaterialTable
            localization={tableLocalization}
            icons={tableIcons}
            title={loading.is ? loading.message : "Formulários"}
            data={forms}
            columns={[
                {
                    title: 'Nome', field: 'title'
                }, {
                    title: 'Inscritos', field: 'inscricoes_count'
                }
            ]}
            actions={[
                {
                    icon: () => <Visibility />,
                    tooltip: 'Visualizar',
                    onClick: (_event, rowData: any) => {
                        history.push(`/form/inscricoes/${rowData.slug}`)
                    }
                }
            ]}
            options={{
                actionsColumnIndex: -1,
                pageSize: 10,
            }}
        />
    </div>
}