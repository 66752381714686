import { Box, Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import SharedTitle from './SharedTitle';
import { OpenInNew } from '@material-ui/icons';
import axios_instance from '../../../../services/auth/jwt/config';
import sweetAlert from '../../../utils/sweetAlert';

interface ConvertObraOcurrenceProps {
    ocurrence: any,
    closeIt: () => void
}

export default function ConvertObraOcurrence({ ocurrence, closeIt, ...props }: ConvertObraOcurrenceProps) {
    const [tipos, setTipos] = useState<{ id: number, nome: string }[]>([]);
    const [tecnicos, setTecnicos] = useState<{ id: number, nome: string }[]>([]);
    const [tecnicosAuxiliares, setTecnicosAuxiliares] = useState<number[]>([]);
    const [tecnicoPrincipal, setTecnicoPrincipal] = useState<number>(0);
    const [loading, setLoading] = useState({ message: '', status: false });
    const [editing, setEditing] = useState({
        solicitante: '',
        local: '',
        endereco: '',
        tipo_id: '',
        descricao: ''
    });

    useEffect(() => {
        axios_instance.get<{ success: boolean, alert: number, counter: any, message: string, status: any[], tipos: any[], tecnicos: any[] }>(`obra/ordem_servico/counter`).then((response) => {
            let r = response.data
            if (r.success) {
                setTipos(r.tipos)
                setTecnicos(r.tecnicos)
            } else {
                sweetAlert('error', 'Erro', r.message)
            }
        }).catch((error) => {
            sweetAlert('error', 'Erro', 'Erro ao carregar os contadores')
        })
    }, [])

    useEffect(() => {
        setEditing({
            solicitante: ocurrence.solicitante.nome,
            local: '',
            endereco: ocurrence.endereco,
            tipo_id: '',
            descricao: ocurrence.descricao
        })
    }, [ocurrence])

    const onSave = () => {
        setLoading({ message: 'Salvando...', status: true })
        let form = {
            solicitante: editing.solicitante,
            local: editing.local,
            endereco: editing.endereco,
            tipo_id: editing.tipo_id,
            descricao: editing.descricao,
            tecnicos: [tecnicoPrincipal, ...tecnicosAuxiliares],
            ocorrencia_id: ocurrence.id
        }
        axios_instance.post<{ success: boolean, message: string }>(`obra/ordem_servico/save`, form).then((response) => {
            let r = response.data
            if (r.success) {
                closeIt()
                sweetAlert('success', 'Sucesso', r.message)
            } else {
                sweetAlert('error', 'Erro', r.message, () => {
                    setLoading({ message: '', status: false })
                })
            }
        }).catch((error) => {
            sweetAlert('error', 'Erro', 'Erro ao salvar a ordem de serviço', () => {
                setLoading({ message: '', status: false })
            })
        }).finally(() => {
            setLoading({ message: '', status: false })
        })
    }

    if (loading.status) {
        return <Box
            height={500}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center">
            <CircularProgress />
            <Box mt={4} fontWeight={700}>
                {loading.message}
            </Box>
        </Box>
    }

    return <Box>
        <SharedTitle title="Abrir Ordem de Serviço" icon={<OpenInNew />} />
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextField
                        label="Nome do Solicitante"
                        fullWidth
                        required
                        variant="outlined"
                        value={editing.solicitante}
                        onChange={(e) => setEditing({ ...editing, solicitante: e.target.value })}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        label="Local"
                        fullWidth
                        required
                        variant="outlined"
                        value={editing.local}
                        onChange={(e) => setEditing({ ...editing, local: e.target.value })}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        label="Endereço"
                        fullWidth
                        required
                        variant="outlined"
                        value={editing.endereco}
                        onChange={(e) => setEditing({ ...editing, endereco: e.target.value })}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl variant="outlined" fullWidth required>
                        <InputLabel required>Tipo de Serviço</InputLabel>
                        <Select
                            value={editing.tipo_id}
                            //@ts-ignore
                            onChange={(e) => setEditing({ ...editing, tipo_id: e.target.value })}
                            label="Tipo de Serviço"
                            required
                        >
                            {tipos.map((t, index) => (
                                <MenuItem key={index} value={t.id}>{t.nome}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth required>
                        <InputLabel required>Técnico Responsável</InputLabel>
                        <Select
                            value={tecnicoPrincipal}
                            required
                            //@ts-ignore
                            onChange={(e) => setTecnicoPrincipal(e.target.value)}
                            label="Técnico Responsável"
                        >
                            {tecnicos.map((t, index) => (
                                <MenuItem key={index} value={t.id}>{t.nome}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel>Técnicos Auxiliares</InputLabel>
                        <Select
                            multiple
                            value={tecnicosAuxiliares}
                            //@ts-ignore
                            onChange={(e) => setTecnicosAuxiliares(e.target.value)}
                            label="Técnicos Auxiliares"
                        >
                            {tecnicos.map((t, index) => (
                                <MenuItem key={index} value={t.id}>{t.nome}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Descrição"
                        fullWidth
                        variant="outlined"
                        multiline
                        required
                        minRows={3}
                        value={editing.descricao}
                        onChange={(e) => setEditing({ ...editing, descricao: e.target.value })}
                    />
                </Grid>
            </Grid>
        </Box>
        <Box mt={2} display="flex" justifyContent="space-between">
            <Button variant="contained" color="secondary" onClick={closeIt}>Cancelar</Button>
            <Button
                variant="contained" color="primary" onClick={onSave}
            >
                Salvar
            </Button>
        </Box>
    </Box>
}