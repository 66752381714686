import MaterialTable from '@material-table/core';
import { CloudDownload, Delete, Message, RecentActors, Visibility } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { tableIcons, tableLocalization } from '../../../@jumbo/constants/TableConstants';
import axios_instance from '../../../services/auth/jwt/config';
import { useParams } from 'react-router';
import ViewInscricao from './ViewInscricao';
import sweetAlert from '../../../f5/utils/sweetAlert';

interface InscricoesFormProps {

}

export default function InscricoesForm({ ...props }: InscricoesFormProps) {
    const [form, setForm] = useState({
        title: '', columns: [],
    })
    const [inscricoes, setInscricoes] = useState<any[]>([])
    const [steps, setSteps] = useState([])
    const [success, setSuccess] = useState({ has: false, message: '' })
    const [loading, setLoading] = useState({ is: true, message: 'Carregando' })
    const [error, setError] = useState({ has: false, message: '' })
    const [askDelete, setAskDelete] = useState({ open: false, id: '', message: '' })
    const [viewing, setViewing] = useState({ open: false, data: {}, user: {}, id: '' })
    const [reply, setReply] = useState({ open: false, id: 0 })
    const { slug } = useParams<{ slug: string }>()

    useEffect(() => {
        axios_instance.get<{ success: boolean, form: any, inscricoes: any[], steps: any }>(`form/inscricoes/${slug}`).then((response) => {
            let r = response.data
            if (r.success) {
                setForm(r.form)
                setInscricoes(r.inscricoes)
                setSteps(r.steps)
            }
        }).catch(() => {
            sweetAlert('error', 'Erro ao carregar inscrições')
        }).finally(() => {
            setLoading({ is: false, message: '' })
        })
    }, [slug])

    /* const onExport = () => {
        setLoading({ is: true, message: 'Exportando' })
        axios_instance.get(`api/form/inscricoes/${id}/export`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem('client_key')}`,
                'Accept': 'application/json'
            },
        }).then(res => res.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                let name = form.title.replace(/ /g, '_')
                a.download = `${name}.xlsx`;
                a.click();
            }).catch(() => {
                setError({ has: true, message: 'Erro ao exportar' })
            }).finally(() => {
                setLoading({ is: false, message: '' })
            })
    } */

    const onDelete = (id: string) => {
        setAskDelete({ open: false, id: '', message: '' })
        axios_instance.post<{ success: boolean, message: string }>(`form/inscricoes/${askDelete.id}/delete`, {}).then((response) => {
            let r = response.data
            if (r.success) {
                setSuccess({ has: true, message: 'Inscrição excluída' })
                setInscricoes(inscricoes.filter((i: any) => i.id !== askDelete.id))
            } else {
                setError({ has: true, message: 'Erro ao excluir inscrição' })
            }
        }).catch(() => {
            setError({ has: true, message: 'Erro ao excluir inscrição' })
        })
    }

    const onDone = () => {
        setSuccess({ has: false, message: '' })
        setLoading({ is: false, message: '' })
        setError({ has: false, message: '' })
        setAskDelete({ open: false, id: '', message: '' })
    }

    return <div className="app-wrapper">
        <MaterialTable
            localization={tableLocalization}
            icons={tableIcons}
            title={loading.is ? loading.message : form.title}
            data={inscricoes}
            columns={form?.columns ?? []}
            actions={[
                {
                    icon: () => <Visibility />,
                    tooltip: 'Visualizar',
                    onClick: (_event, rowData: any) => {
                        setViewing({ open: true, data: rowData.data, user: rowData.user, id: rowData.id })
                    }
                }, /* {
                    icon: () => <CloudDownload />,
                    tooltip: 'Exportar', isFreeAction: true,
                    onClick: () => {
                        onExport()
                    }
                }, {
                    icon: () => <Message />,
                    tooltip: 'Responder',
                    onClick: () => {
                        setReply({ open: true, id: 0 })
                    }

                },*/  {
                    icon: () => <Delete />,
                    tooltip: 'Excluir',
                    onClick: (_event, rowData: any) => {
                        setAskDelete({ open: true, id: rowData.id, message: 'Deseja excluir esta inscrição?' })
                    }
                },
            ]}
            options={{
                actionsColumnIndex: -1,
                pageSize: 10,
            }}
        />
        <ViewInscricao
            open={viewing.open} onClose={() => setViewing({ open: false, data: {}, user: {}, id: '' })}
            steps={steps} data={viewing.data} user={viewing.user} id={viewing.id}
        />
        {/* <ModalFullLSE
            error={error} success={success} loading={loading}
            onDone={onDone}
        />
        <SweetAlert
            show={askDelete.open} warning showCancel
            confirmBtnText="Sim, excluir" confirmBtnBsStyle="danger"
            title="Atenção" cancelBtnText="Cancelar"
            onConfirm={() => {
                onDelete(askDelete.id)
            }}
            onCancel={() => {
                onDone()
            }}
        >
            {askDelete.message}
        </SweetAlert> */}
        {/* <RespostaUsuario
            open={reply.open} onClose={() => setReply({ open: false, id: 0 })}
            onEnd={() => setReply({ open: false, id: 0 })} user={reply.id} link={`api/form/inscricoes/reply`}
        /> */}
    </div>
}