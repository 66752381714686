import { Grid } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router';
import SpecialCard from './SpecialCard';
import { useDispatch } from 'react-redux';
import { setDepartmentOmbudsman } from '../../redux/actions/f5-actions/department-ombudsman';

export default function ChooseSpecial({ items, ...props }) {
    const dispatch = useDispatch();
    const history = useHistory();

    const handleClick = (link = '', sector = null) => {
        dispatch(setDepartmentOmbudsman(sector));
        history.push(link);
    };

    return (
        <Grid container>
            {items ? (
                items.map(item => (
                    <Grid item xs={12} md={6} key={item.id}>
                        <SpecialCard
                            title={item.title}
                            color={'#3f51b5'}
                            handleClick={() => handleClick(item.link, item.sector)}
                            data={item.counters ?? []}
                        />
                    </Grid>
                ))
            ) : (
                <React.Fragment />
            )}
        </Grid>
    );
}
