import { CircularProgress } from '@material-ui/core';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import axios_instance from '../../../services/auth/jwt/config';

interface FormFileProps {
    id: string,
    type: string,
    name: string,
}

export default function FormFile({ id, type, name, ...props }: FormFileProps) {
    const [image, setImage] = useState('')
    const [success, setSuccess] = useState(false)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState({ has: false, message: '' })

    useEffect(() => {
        if (id && type) {
            axios_instance.get(`form/anexo/show/${id}/${type}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('client_key')}`
                }
                // @ts-ignore
            }).then(res => res.blob()).then(blob => {
                if (blob.type === 'application/json') {
                    setImage('')
                } else {
                    setImage(URL.createObjectURL(blob))
                }
            }).catch(err => {
                setError({ has: true, message: err.message })
            }).finally(() => {
                setLoading(false)
            })
        }
    }, [id, type])

    if (loading) {
        return <CircularProgress />
    }
    if (!image) {
        return <div>Sem Anexo</div>
    }
    return <img
        src={image} alt={name} {...props} style={{ height: 100, width: 100, objectFit: 'contain' }}
        //baixar imagem
        onClick={() => {
            const a = document.createElement('a')
            a.href = image
            a.download = `${id}_${name}`
            a.click()
        }}
    />
}