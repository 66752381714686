import { Avatar, Box, Button, ButtonGroup, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Tooltip as ToolerTip, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import { BarChartRounded, Clear, Print, ShowChart } from '@material-ui/icons';
import { Area, AreaChart, Bar, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis, BarChart } from 'recharts';
import axios_instance from '../../../services/auth/jwt/config';

interface ObraOrderDashboardProps {

}

type Funcionario = {
    nome: string,
    id: number,
    secundarias: number,
    principais: number,
}

export default function ObraOrderDashboard({ ...props }: ObraOrderDashboardProps) {
    const [funcionarios, setFuncionarios] = useState<Funcionario[]>([])
    const [timeMode, setTimeMode] = useState<'week' | 'month' | 'year'>('week')
    const [selectedFuncionarios, setSelectedFuncionarios] = useState<number[]>([])
    const [loading, setLoading] = useState({ is: true, message: 'Carregando' })
    const [dashboard, setDashboard] = useState({
        meses: [],
        tipos: [],
    })

    useEffect(() => {
        const controller = new AbortController()
        setLoading({ is: true, message: 'Carregando' })
        axios_instance.get<{ success: boolean, funcionarios: Funcionario[], graphs: any }>(`obra/ordem_servico/dashboard`, {
            signal: controller.signal,
            params: {
                funcionarios: selectedFuncionarios,
                mode: timeMode
            }
        }).then((response) => {
            let r = response.data
            if (r.success) {
                setFuncionarios(r.funcionarios)
                setDashboard(r.graphs)
            }
        }).catch((r) => {
            // sweetAlert('error', 'Erro ao carregar inscrições')
        }).finally(() => {
            setLoading({ is: false, message: '' })
        })
    }, [selectedFuncionarios, timeMode])

    const onPrint = () => {
        window.print()
    }

    const getPastPeriod = useCallback(() => {
        switch (timeMode) {
            case 'week':
                return 'Semana Passada'
            case 'month':
                return 'Mês Passado'
            case 'year':
                return 'Ano Passado'
        }
    }, [timeMode])

    const getCurrentPeriod = useCallback(() => {
        switch (timeMode) {
            case 'week':
                return 'Semana Atual'
            case 'month':
                return 'Mês Atual'
            case 'year':
                return 'Ano Atual'
        }
    }, [timeMode])

    // @ts-ignore
    return <PageContainer>
        <Paper>
            <Box p={1}>
                <Grid container spacing={2} alignItems="center" justifyContent="space-between">
                    <Grid item xs={12} md={6} lg={9}>
                        {/* <Box display="flex" justifyContent="space-evenly">
                    {funcionarios.map((item) => <ToolerTip title={`${item.nome}`} key={item.id} placement="top">
                        <Box
                            m={1} p={1} alignItems={"center"} display="flex" flexDirection="column" borderRadius={5}
                            style={{ cursor: 'pointer' }}
                            bgcolor={selectedFuncionarios.includes(item.id) ? 'primary.main' : 'background.paper'}
                            onClick={() => {
                                if (selectedFuncionarios.includes(item.id)) {
                                    setSelectedFuncionarios(selectedFuncionarios.filter((id) => id !== item.id))
                                } else {
                                    setSelectedFuncionarios([...selectedFuncionarios, item.id])
                                }
                            }}
                        >
                            <Avatar>
                                {item.nome.charAt(0).toUpperCase()}
                            </Avatar>
                            <Typography variant="caption" align="center" style={{ color: selectedFuncionarios.includes(item.id) ? 'white' : 'black', textOverflow: 'ellipsis', textWrap: "nowrap", width: 50 }} noWrap>
                                {`${item.nome.split(' ')[0]} ${item.nome.split(' ')?.[1]?.charAt(0).toUpperCase() ?? ''}`}
                            </Typography>
                        </Box>
                    </ToolerTip>)}
                </Box> */}
                        <FormControl fullWidth variant='outlined'>
                            <InputLabel>Funcionários</InputLabel>
                            <Select
                                startAdornment={<IconButton onClick={() => setSelectedFuncionarios([])} >
                                    <Clear />
                                </IconButton>}
                                label="Funcionários"
                                value={selectedFuncionarios}
                                fullWidth
                                multiple
                                // @ts-ignore
                                onChange={(e) => setSelectedFuncionarios(e.target.value)}
                            >
                                {funcionarios.map((funcionario) => (
                                    <MenuItem key={funcionario.id} value={funcionario.id}>
                                        {funcionario.nome}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <Box display="flex" gridGap={1}>
                            <ButtonGroup fullWidth>
                                <Button color="primary" variant={timeMode === 'week' ? 'contained' : 'outlined'} onClick={() => setTimeMode('week')}>
                                    Semana
                                </Button>
                                <Button color="primary" variant={timeMode === 'month' ? 'contained' : 'outlined'} onClick={() => setTimeMode('month')}>
                                    Mês
                                </Button>
                                <Button color="primary" variant={timeMode === 'year' ? 'contained' : 'outlined'} onClick={() => setTimeMode('year')}>
                                    Ano
                                </Button>
                            </ButtonGroup>
                            <Button variant="contained" color="primary" onClick={onPrint}>
                                <Print />
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Paper>
        <Box mt={2}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper>
                        <Box p={2}>
                            <Box mb={3} display="flex" alignItems="center">
                                <ShowChart />
                                <Box ml={1} fontWeight={700}>
                                    Fluxo De Ordens de Serviço
                                </Box>
                            </Box>
                            <Box>
                                <ResponsiveContainer width="100%" height={300}>
                                    <AreaChart data={dashboard?.meses} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                                        <XAxis dataKey="dia" />
                                        <YAxis />
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <Tooltip labelStyle={{ color: 'black' }} itemStyle={{ color: 'black' }} cursor={false} />
                                        <Legend />
                                        <defs>
                                            <linearGradient id="colorCurrent" x1="0" y1="0" x2="0" y2="1">
                                                <stop offset="5%" stopColor="#f7aa1b" stopOpacity={0.8} />
                                                <stop offset="95%" stopColor="#f7aa1b" stopOpacity={0} />
                                            </linearGradient>
                                            <linearGradient id="colorLast" x1="0" y1="0" x2="0" y2="1">
                                                <stop offset="5%" stopColor="#a19b91" stopOpacity={0.8} />
                                                <stop offset="95%" stopColor="#a19b91" stopOpacity={0} />
                                            </linearGradient>
                                        </defs>
                                        <Area
                                            type="monotone"
                                            dataKey="anterior"
                                            name={getPastPeriod()}
                                            stroke="#a19b91"
                                            fill="url(#colorLast)"
                                            fillOpacity={1}
                                        />
                                        <Area
                                            type="monotone"
                                            dataKey="atual"
                                            name={getCurrentPeriod()}
                                            stroke="#f7aa1b"
                                            fill="url(#colorCurrent)"
                                            fillOpacity={1}
                                        />
                                    </AreaChart>
                                </ResponsiveContainer>
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
        <Box mt={2}>
            <Paper>
                <Box p={2}>
                    <Box mb={3} display="flex" alignItems="center">
                        <BarChartRounded />
                        <Box ml={1} fontWeight={700}>
                            Ordens por Funcionário
                        </Box>
                    </Box>
                    <Box>
                        <ResponsiveContainer width="100%" height={400}>
                            <BarChart data={funcionarios} layout='horizontal'>
                                <CartesianGrid strokeDasharray="3 3" />
                                <YAxis type="number" />
                                <XAxis
                                    dataKey="nome"
                                    type="category"
                                    width={200}
                                    tickFormatter={(value) => {
                                        return `${value.split(' ')[0]} ${value.split(' ')?.[1]?.charAt(0).toUpperCase() ?? ''}`
                                    }}
                                />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="principais" label={{ position: 'top' }} name="Principal" fill="#f7aa1b" stackId="A"
                                    background={(props) => {
                                        console.log(props)
                                        if (selectedFuncionarios.includes(props.id)) {
                                            return <rect x={props.x} y={props.y} width={props.width} height={props.height} fill="#a19b9133" rx={5} ry={5} />
                                        }
                                        return false
                                    }}
                                />
                                <Bar dataKey="secundarias" label={{ position: 'top' }} name="Secundário" fill="#a19b91" stackId="A" />
                            </BarChart>
                        </ResponsiveContainer>
                    </Box>
                </Box>
            </Paper>
        </Box>
        <Box mt={2}>
            <Paper>
                <Box p={2}>
                    <Box mb={3} display="flex" alignItems="center">
                        <BarChartRounded />
                        <Box ml={1} fontWeight={700}>
                            Ordens de Serviço por Tipo
                        </Box>
                    </Box>
                    <Box>
                        <ResponsiveContainer width="100%" height={800}>
                            <BarChart data={dashboard?.tipos} layout='vertical'>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis type="number" />
                                <YAxis dataKey="nome" type="category" width={200} />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="passado" label={{ position: 'right' }} name={getPastPeriod()} fill="#a19b91" />
                                <Bar dataKey="presente" label={{ position: 'right' }} name={getCurrentPeriod()} fill="#f7aa1b" />
                            </BarChart>
                        </ResponsiveContainer>
                    </Box>
                </Box>
            </Paper>
        </Box>
    </PageContainer>
}